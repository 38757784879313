var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "form-wrapper",
      attrs: {
        "auto-complete": "on",
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Type", prop: "type_id" } },
        [
          _c(
            "el-select",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.types.loading,
                  expression: "types.loading",
                },
              ],
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255, 255, 255, 0.7)",
              },
              model: {
                value: _vm.form.type_id,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "type_id", $$v)
                },
                expression: "form.type_id",
              },
            },
            _vm._l(_vm.types.items, function (type) {
              return _c("el-option", {
                key: type.id,
                attrs: { label: type.value, value: type.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm.form.type_id
        ? _c(
            "el-form-item",
            { attrs: { label: "Framework", prop: "subtype_id" } },
            [
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.subtypes.loading,
                      expression: "subtypes.loading",
                    },
                  ],
                  attrs: {
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(255, 255, 255, 0.7)",
                  },
                  model: {
                    value: _vm.form.subtype_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "subtype_id", $$v)
                    },
                    expression: "form.subtype_id",
                  },
                },
                _vm._l(_vm.subtypes.items, function (type) {
                  return _c("el-option", {
                    key: type.id,
                    attrs: { label: type.value, value: type.id },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "Notes", prop: "notes" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 2 },
            model: {
              value: _vm.form.notes,
              callback: function ($$v) {
                _vm.$set(_vm.form, "notes", $$v)
              },
              expression: "form.notes",
            },
          }),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-button",
        { attrs: { disabled: _vm.creating }, on: { click: _vm.handleSubmit } },
        [_vm._v(" Create ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }